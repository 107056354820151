

/**
 * Forms
 */
 form {
     label.disabled {
        color: #868e96;
     }

    .error {
        color: red;
        margin-left: 10%;
        margin-top: -1.9em;
        margin-bottom: 0.4em;
    }
}

label {
    font-weight: bold;

    &.required {
        &:after {
            content: " *";
            color: darkred;
        }
    }
}

fieldset, .grid-table {

    &.half {
        margin-top: 2pt;
        width: 49%;
        &.left {
            float: left;
        }
        &.right {
            float: right;
        }
    }

    label {
        font-weight: bold;
        display: block;
    }

    input, textarea, select, small {
        margin-left: 10%;
    }

    textarea {
        width: 80%;
        height: 10ex;
    }

    input[type=text], input[type=password], select {
        width: 80%;
    }

    input {
        padding-left: 10pt;
        &.short {
            width: 20%;
        }
        &.tiny {
            width: 50pt;
        }
    }

    .input-container {
        display: inline-block;
        position: relative;
        width: 100%;
        .input-icon {
            margin-left: -25px;
            margin-top: -25px;
            position: relative;
            z-index: 2;
        }
    }

    .value-list-item {
        margin-bottom: 5pt;
        input {
            margin-right: 5pt;
        }
    }

    .actionbtn-input {
        width: 80%;
        margin-left: 10%;
        input {
            margin: 0;
            width: 90%;
        }

        .without-margin {
            margin: 0 !important;
        }
    }
}

.grid-table {
    input, textarea, select {
        margin-left: 0.3em;
    }
}

.compact {

    border: 1px solid rgba(131,21,6,0.05);
    border-radius: 10px;
    padding: 10pt;
    width: 48%;
    margin-right: 2%;
    margin-bottom: 1em;
    float: left;

    button {
        margin-left: 2em;
    }

    fieldset {

        margin-bottom: -2pt;
        border: none;
        border-radius: 0;
        padding: 2pt;
        background-color: transparent;
        font-size: 80%;
        box-shadow: none;

        label {
            float: left;
        }

        textarea {
            width: 80%;
            height: 10ex;
        }

        input[type=text] {
            width: 60%;
            float: right;
        }

        input {
            padding-left: 10pt;
            &.short {
                width: 20%;
            }
            &.tiny {
                width: 50pt;
            }
        }

        .dropdown select {
            margin-left: 61pt;
        }

        .value-list-item {
            margin-bottom: 5pt;
            input {
                margin-right: 5pt;
            }
        }
    }
}

.grid-tr {
    display: flex;           display: -webkit-flex;
    flex-direction: row;     -webkit-flex-direction: row;
    flex-grow: 0;            -webkit-flex-grow: 0;
    flex-wrap: wrap;         -webkit-flex-wrap: wrap;
    width: 100%;
    border-collapse: collapse;
    padding-top: 0.2em;

    .grid-td, .grid-th {
        flex-grow: 0;            -webkit-flex-grow: 0;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 7.5em;
    }
}

.grid-tr.header, .capacity-row.header {
    background-color: rgba(154, 63, 32, 0.7);
    color: white;
    font-weight: bold;
    padding-top: 8px;
    padding-bottom: 8px;
}


.capacity-row {
    display: flex;           display: -webkit-flex;
    flex-direction: row;     -webkit-flex-direction: row;
    flex-grow: 0;            -webkit-flex-grow: 0;
    width: 100%;
    padding: 0.2em;
    border-bottom: 0 solid #e0e0e0;
    font-size: 0.8em;

   .grid-table {
        flex: 1 1 auto;
    }

    .actions {
        flex: 0 0 4em;
        text-align: right;
    }

    button {
        padding: 0.2em 0.5em 0.2em 0.5em;
    }
}

.password-wrap {
    position: relative;

    .password-eye {
        position: absolute;
        top:50%;
        right: 2px;
        transform: translateY(-50%);
    }
}

.form-control-readonly {
    &[readonly] {
        background-color: #fff !important;
    }
}

.form-group .form-select {
    padding: 2px 0 3px 10pt;
}
